import { navigate } from "gatsby";
import React, { useState } from "react"
import { Form, FormGroup } from "react-bootstrap"

import Layout from '../components/layout'

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&")
}

export default function Contact() {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({})

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...state,
        }),
      }).then(() => navigate("/thankyou/")).catch(error => alert(error))
    }
    setValidated(true);
  }

  //let title="Contact"
  //let description="Contact us for information, custom inquiries, or any questions and problems."

  return (
    <Layout>
      <div className="text-center pt-5">
        <h2 className="text-dshade">Contact</h2>
        <Form noValidate validated={validated} method="post" onSubmit={handleSubmit} name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="row py-2">
            <div className="col">
              <FormGroup controlId="contactFirstName">
                <Form.Label>First</Form.Label>
                <Form.Control onChange={handleChange} required type="text" name="contactFirstName" placeholder="John" />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup controlId="contactLastName">
                <Form.Label>Last</Form.Label>
                <Form.Control onChange={handleChange} required type="name" name="contactLastName" placeholder="Smith" />
              </FormGroup>
            </div>
          </div>
          <div className="row py-2">
            <FormGroup controlId="contactEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control onChange={handleChange} required type="email" name="contactEmail" placeholder="Enter email" />
            </FormGroup>
          </div>
          <div className="row py-2">
            <div className="col">
              <FormGroup controlId="contactMessage">
                <Form.Label>Problem description</Form.Label>
                <Form.Control onChange={handleChange} required as="textarea"  name="contactMessage" rows={5} placeholder="Tell us what's on your mind!"/>
              </FormGroup>
            </div>
          </div>
          <div className="row py-2">
            <div className="col">
              <button type="submit"
                      className="btn btn-brand"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  )
}
